import React from "react";
import arrow from "../../assets/home/section-3/arrow.svg";
import { servicesCardAll } from "../../data";

const Services = () => {
  return (
    <section className="services" id="services">
      <div className="container">
        <div className="services-inner md:flex justify-between">
          <div data-aos="fade-up" className="services-left md:w-4/12">
            <h2> Zero Complication</h2>
            <p className="para">
              la simplicité est la clé du succès. Nous vous offrons des
              solutions qui ne sont pas seulement faciles à utiliser, mais
              incroyablement efficaces.
            </p>
            <div>
              <img src={arrow} alt="" />
            </div>
          </div>
          <div className="services-right md:w-8/12 md:pl-10">
            <div className="services-right-inner flex justify-between items-start">
              {servicesCardAll.map((item) => {
                return (
                  <div data-aos="fade-up" data-aos-delay={item.id} key={item.id} className="card">
                    <div className="card-image">
                      <img src={item.image} alt="" />
                    </div>
                    <h3 className="card-title">{item.title}</h3>
                    <p className="card-text">{item.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
