import React, { useState } from "react";
import banner from "../../assets/home/section-5/contact-banner.png";
import twitter from "../../assets/home/section-5/Twitter.svg";
import instagram from "../../assets/home/section-5/Instagram.svg";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    nom: "",
    nomdu: "",
    message: ""
  });

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let validationErrors = {};
    if (!validateEmail(formData.email)) {
      validationErrors.email = "Invalid email address";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    emailjs.send('service_hwchvxs', 'template_xbtxqpz', formData, '_UqDbrlJK1WZqAUym')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    setFormData({
      email: "",
      nom: "",
      nomdu: "",
      message: ""
    });
  };

  return (
    <section className="contact-us" id="contact">
      <div className="container">
        <div className="contact-us-inner md:flex justify-between items-center">
          <div className="contact-us-left" data-aos="zoom-in-up">
            <img src={banner} alt="Contact Banner" />
          </div>
          <div className="contact-us-right" data-aos="zoom-in-left">
            <span>contactez nous</span>
            <h2>Collaborons ! C'est parti !</h2>
            <form className="contact-us-form" onSubmit={handleSubmit}>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
              <div className="flex gap-5">
                <div>
                  <label htmlFor="nom">Nom</label>
                  <input
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="nomdu">Nom du projet</label>
                  <input
                    type="text"
                    name="nomdu"
                    value={formData.nomdu}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Write Message..."
              ></textarea>
              <div className="social-media flex justify-end items-center gap-5">
                <Link to="https://www.behance.net/nykse">
                  <img src={twitter} alt="Twitter" />
                </Link>
                <Link to="https://www.instagram.com/nykse.ch">
                  <img src={instagram} alt="Instagram" />
                </Link>
              </div>
              <Button type="submit">Send Message</Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
