import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Qui from "./pages/Qui";
import Declaration from "./pages/Declaration";
import TermsCondition from "./pages/TermsCondition";
import FourOFour from "./pages/FourOFour";

const Path = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/qui" element={<Qui />} />
      <Route path="/declaration" element={<Declaration />} />
      <Route path="/terms-condition" element={<TermsCondition />} />
      <Route path="/*" element={<FourOFour />} />
    </Routes>
  );
};

export default Path;
