import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../styles/declarationAndTermCondition/custom.scss"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TermsConditionMain from "../components/terms-condition/TermsConditionMain";

const TermsCondition = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
      once: false, // whether animation should happen only once - while scrolling down
    });
    AOS.refresh(); // refresh animations when the component is loaded
  }, []);

  return (
    <div className="overflow-hidden">
      <Navbar />
      <TermsConditionMain />
      <Footer />
    </div>
  );
};

export default TermsCondition;
