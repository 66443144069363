import React from "react";
import logo from "../assets/footer/footer-logo.svg";
import twitter from "../assets/footer/Twitter.svg";
import instagram from "../assets/footer/Instagram.svg";
import scrollToTop from "../assets/footer/scroll-to-top.svg"; // Import the arrow image
import { Link } from "react-router-dom";
import { HashLink as LinkHash } from 'react-router-hash-link';
import "../styles/footer.scss";

const Footer = () => {
  // Function to scroll to top
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-inner">
          <div className="footer-logo flex flex-col gap-10 md:gap-16 md:w-4/12">
            <div data-aos="fade-right">
              <img src={logo} alt="Logo" />
            </div>

            <div
              className="social-btn social-media"
              data-aos="fade-up-right"
              data-aos-delay="200"
            >
              <Link to={"https://www.behance.net/nykse"} target="_blank">
                <img src={twitter} alt="Twitter" />
              </Link>
              <Link to={"https://www.instagram.com/nykse.ch/"} target="_blank">
                <img src={instagram} alt="Instagram" />
              </Link>
            </div>
          </div>
          <div className="site-web md:w-2/12" data-aos="fade-up-left">
            <h3>Site web</h3>
            <LinkHash to={"/#services"}>Services</LinkHash>
            <LinkHash to={"/#contact"}>Contact</LinkHash>
            <LinkHash to={"/#avis"}>Avis</LinkHash>
            <LinkHash to={"/qui"}>Qui sommes-nous ?</LinkHash>
          </div>
          <div
            className="site-web md:w-2/12"
            data-aos="fade-up-left"
            data-aos-delay="200"
          >
            <h3>Autres</h3>
            <Link to={"https://g.page/r/CS6I_SK6C7krEAI/review"} target="_blank">Google Reviews</Link>
          </div>
        </div>
        <div className="footer-bottom">
          <p
            className="para text-center"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            Copyright © 2024 Nykse | Tous droits réservés |{" "}
            <Link to={"/terms-condition"}>Conditions Générales</Link> |{" "}
            <Link to={"/Declaration"}>Déclaration de Confidentialité</Link>
          </p>
        </div>
        <button className="scroll-to-top" onClick={handleScrollToTop}>
          <img src={scrollToTop} alt="Scroll to top" />
        </button>
      </div>
    </footer>
  );
};

export default Footer;
