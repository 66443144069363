import React from "react";
import shapehero from "../../assets/qui/hero-shape.svg";
import banner from "../../assets/qui/banner.png";

const QuiHero = () => {
  return (
    <>
      <section className="qui-hero">
        <div className="qui-hero-inner">
          <img src={shapehero} alt="shapehero" className="hero-shape" />
          <img
            className="banner"
            src={banner}
            alt="banner"
            data-aos="fade-up"
          />
        </div>
      </section>
    </>
  );
};

export default QuiHero;
