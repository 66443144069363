import React, { useEffect } from "react";
import "../styles/qui.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../components/Navbar";
import { QuiHero, WeAre } from "../components/qui";
import Footer from "../components/Footer";

const Qui = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
      once: false, // whether animation should happen only once - while scrolling down
    });
    AOS.refresh(); // refresh animations when the component is loaded
  }, []);

  return (
    <div className="overflow-hidden">
      <Navbar page={"qui"} />
      <QuiHero />
      <WeAre />
      <Footer />
    </div>
  );
};

export default Qui;
