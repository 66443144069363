import React from "react";
import guys1 from "../../assets/qui/1/guys1.png";
import { Link } from "react-router-dom";
import instagram from "../../assets/qui/Instagram.svg";
import twitter from "../../assets/qui/Twitter.svg";
import linkedin from "../../assets/qui/LinkedIn.svg";
import im1 from "../../assets/qui/1/im1.svg";
import im2 from "../../assets/qui/1/im2.svg";
import s2_shape1 from "../../assets/qui/2/1.svg";
import s2_shape2 from "../../assets/qui/2/2.svg";
import guys2 from "../../assets/qui/2/guys2.png";
import s3_shape1 from "../../assets/qui/3/1.svg";
import s3_shape2 from "../../assets/qui/3/2.svg";
import guys3 from "../../assets/qui/3/guys3.png";
import s4_shape1 from "../../assets/qui/4/1.svg";
import s4_shape2 from "../../assets/qui/4/2.svg";
import guys4 from "../../assets/qui/4/guys4.png";

const WeAre = () => {
  return (
    <>
      <section className="we-are">
        <div className="container">
          <div className="we-are-inner">
            <h2 className="nunito" data-aos="fade-right">Qui sommes-nous ?</h2>
            <p className="para noto" data-aos="fade-right" data-aos-delay="100">
              Nykse est un studio né le 9 février 2024, fondé par deux étudiants
              de la filière Médiamatique du CPNV. Leur rêve ? Donner vie à des
              idées nouvelles et inspirantes. Ils croient que chaque création
              est une aventure où l’art et la technologie se rencontrent pour
              enrichir notre vision du monde. Si notre travail te plaît,
              n’hésite pas à nous rejoindre pour créer ensemble.
            </p>
          </div>
        </div>
      </section>
      <section className="guys">
        <div className="guys-inner">
          <div className="bg-shape">
            <img className="mr-[-100px]" src={im1} alt="" />
            <img src={im2} alt="" />
          </div>
          <div className="container">
            <div className="flex max-md:flex-col items-center guys-main">
              <div className="guys-image" data-aos="fade-up-right">
                <img src={guys1} alt="" />
              </div>
              <div className="guys-content">
                <div className="content">
                  <p className="para pb-4" data-aos="fade-up-left">
                    En 2018, j'ai commencé à créer des montages vidéo de jeux
                    comme Minecraft et Fortnite pour ma chaîne YouTube, affinant
                    mes compétences. En 2019, j'ai exploré le dessin, réalisant
                    des portraits de personnages de jeux & mangas.
                  </p>
                  <p className="para" data-aos="fade-up-left">
                    Après ma scolarité en Suisse, j'ai passé un an à
                    perfectionner mes compétences en multimédia, ce qui m'a
                    conduit à la Médiamatique. Aujourd'hui, je travaille sur des
                    projets variés, combinant mes passions pour la création
                    visuelle et la technologie.
                  </p>
                  <div className="social-media">
                    <Link to={"https://www.instagram.com/theoblondel.ch/"} data-aos="fade-left">
                      <img src={instagram} alt="" />
                    </Link>
                    <Link to={"https://www.behance.net/JusteTheo"} data-aos="fade-left" data-aos-delay="100">
                      <img src={twitter} alt="" />
                    </Link>
                    <Link to={"https://www.linkedin.com/in/theo-blondel-6952432aa/"} data-aos="fade-left" data-aos-delay="200">
                      <img src={linkedin} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="guys second-square">
        <div className="guys-inner bg-white">
          <div className="bg-shape">
            <img src={s2_shape2} alt="" className="relative z-[-1]" />
            <img className="ml-[-100px]" src={s2_shape1} alt="" />
          </div>
          <div className="container mt-[-100px]">
            <div className="flex max-md:flex-col-reverse items-center guys-main">
              <div className="guys-content">
                <div className="content">
                  <p className="para pb-4" data-aos="fade-up-right">
                  Je me nomme Robin Chappuis et je suis actuellement étudiant en médiamatique. Au cours de mon parcours, j'ai eu l'opportunité de travailler avec divers clients,
                  ce qui m'a permis de développer une expertise particulière dans le design graphique et le développement web (HTML, CSS, JS).
                  </p>
                  <p className="para" data-aos="fade-up-right">
                  En parallèle, j'ai également acquis de l'expérience en vidéo, photographie et marketing. Passionné par le monde digital,
                  je mets à profit mes compétences variées pour réaliser des projets créatifs et innovants.
                  </p>
                  <div className="social-media">
                    <Link to={"https://www.instagram.com/irobin.ch"} data-aos="fade-right">
                      <img src={instagram} alt="" />
                    </Link>
                    <Link to={"https://www.behance.net/robinchappuis2"} data-aos="fade-right" data-aos-delay="100">
                      <img src={twitter} alt="" />
                    </Link>
                    <Link to={"https://www.linkedin.com/in/robin-chappuis-3929782b1"} data-aos="fade-right" data-aos-delay="200">
                      <img src={linkedin} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="guys-image" data-aos="fade-up-left">
                <img src={guys2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="guys md:mt-[-100px]">
        <div className="guys-inner">
          <div className="bg-shape">
            <img className="mr-[-100px]" src={s3_shape1} alt="" />
            <img src={s3_shape2} alt="" />
          </div>
          <div className="container">
            <div className="flex max-md:flex-col items-center guys-main">
              <div className="guys-image" data-aos="fade-up-right">
                <img src={guys3} alt="" />
              </div>
              <div className="guys-content">
                <div className="content">
                  <p className="para pb-4" data-aos="fade-up-left">
                  Je m'appelle Lucien Contesse, j'ai 18 ans et je suis étudiant en Médiamatique.
                  Polyvalent et créatif, je me spécialise en design, marketing, photographie, vidéo, ainsi que dans les retouches associées.
                  </p>
                  <p className="para" data-aos="fade-up-left">
                  Je développe également des sites web, que ce soit avec WordPress ou en utilisant des langages de programmation tels que HTML et CSS.
                  </p>
                  <div className="social-media">
                    <Link to={"https://www.instagram.com/c.l._.design"} data-aos="fade-left">
                      <img src={instagram} alt="" />
                    </Link>
                    <Link to={"https://www.behance.net/luciencontesse"} data-aos="fade-left" data-aos-delay="100">
                      <img src={twitter} alt="" />
                    </Link>
                    <Link to={"https://www.linkedin.com/in/lucien-contesse-a097972b2"} data-aos="fade-left" data-aos-delay="200">
                      <img src={linkedin} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="guys four-square md:pb-[200px] max-md:pb-[70px]">
        <div className="guys-inner bg-white">
          <div className="bg-shape">
            <img src={s4_shape2} alt="" className="relative z-[-1]" />
            <img className="ml-[-100px]" src={s4_shape1} alt="" />
          </div>
          <div className="container">
            <div className="flex max-md:flex-col-reverse items-center guys-main">
              <div className="guys-content">
                <div className="content">
                  <p className="para pb-4" data-aos="fade-up-right">
                    Je suis Maël Descouens-Cornet, un jeune Franco-Tahitien de 17 ans basé en Suisse. Passionné de créativité depuis toujours,
                    je me suis concentré sur le design graphique. Actuellement étudiant en mediamatique, j'ai réalisé de nombreux projets scolaires et pour quelques clients.
                  </p>
                  <p className="para" data-aos="fade-up-right">
                    Organisé et discipliné, je crois en un travail structuré tout en laissant libre cours à ma créativité. En dehors du design,
                    je pratique divers sports et activités de plein air, tout en appréciant les moments calmes pour perfectionner mes projets.
                  </p>
                  <div className="social-media">
                    <Link to={"https://www.instagram.com/mdsco.design/"} data-aos="fade-right">
                      <img src={instagram} alt="" />
                    </Link>
                    <Link to={"https://www.behance.net/Mael_dsco"} data-aos="fade-right" data-aos-delay="100">
                      <img src={twitter} alt="" />
                    </Link>
                    <Link to={"https://www.linkedin.com/in/ma%C3%ABl-descouens-3a704b266/"} data-aos="fade-right" data-aos-delay="200">
                      <img src={linkedin} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="guys-image" data-aos="fade-up-left">
                <img src={guys4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeAre;
