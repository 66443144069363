import React from "react";
import { BrowserRouter } from "react-router-dom";
import Path from "./path";
import Video from "./components/Video"

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Video />
        <Path />
      </BrowserRouter>
    </>
  );
};

export default App;
