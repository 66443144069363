import React, { useEffect } from "react";
import fourofour from "../assets/404.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import "../styles/declarationAndTermCondition/custom.scss";

const FourOFour = () => {
  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
      once: false, // whether animation should happen only once - while scrolling down
    });
    AOS.refresh(); // refresh animations when the component is loaded
  }, []);

  return (
    <section className="four-o-four">
      <div className="no-page-inner">
        <img data-aos="fade-down" src={fourofour} alt="" />
        <h3 data-aos="zoom-in" data-aos-delay="300" className="nunito">
          Oups, cette page est introuvable.
        </h3>
        <div data-aos="fade-up" data-aos-delay="600">
          <button onClick={handleBack} className="nunito">
            revenir en lieu sûr
          </button>
        </div>
      </div>
    </section>
  );
};

export default FourOFour;
