import React from 'react';
import border from "../../assets/home/border-hero.svg";
import banner from "../../assets/home/hero-banner.svg";
import shape from "../../assets/home/hero-shape.svg";
import shape1 from "../../assets/home/hero-shape1.svg";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="home-hero flex justify-center">
      <div className="shape absolute w-full">
        <img src={shape} alt="" />
      </div>
      <div className="shape1 absolute w-full">
        <img src={shape1} alt="" />
      </div>
      <div className="container absolute bottom-0">
        <div className="home-hero-inner lg:flex justify-between items-end">
          <div className="home-hero-left">
            <h2 data-aos="fade-up-right">
              Rafraîchissez <br /> votre{" "}
              <span className="relative">
                univers !{" "}
                <img
                  className="absolute -bottom-2 left-0 w-full"
                  src={border}
                  alt="border"
                />
              </span>
            </h2>
            <p className="para" data-aos="fade-up-right" data-aos-delay="200">
              Nous nous engageons pleinement à valoriser et à prendre soin de
              toi ainsi que de l'identité de ta marque. Grâce à notre gamme
              étendue de services personnalisés, nous sommes prêts à propulser
              ton projet vers de nouveaux sommets avec une dose exceptionnelle
              de créativité.
            </p>
            <a href="/#contact">
              <Button data-aos="fade-up-right" className="hero-btn">Obtenir un devis</Button>
            </a>
          </div>
          <div className="home-hero-right" data-aos="fade-down" data-aos-anchor-placement="center-bottom">
            <img src={banner} alt="banner" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
