import React from "react";
import { Link } from "react-router-dom";

const DeclarationMain = () => {
  return (
    <section className="declaration-main">
      <div className="container">
        <div className="declaration-inner">
          <h2 data-aos="fade-up" className="nunito">Déclaration de Confidentialité</h2>
          <p data-aos="fade-up" className="para">
            Votre confiance est très importante pour nous. Nous partageons cette
            déclaration de confidentialité pour vous aider à comprendre ce que
            nous pouvons faire avec les données personnelles que nous obtenons
            de vous
          </p>
          <h4 data-aos="fade-right">Votre Consentement</h4>
          <p data-aos="fade-up" className="para">
            En naviguant sur notre site et en accédant à nos services, vous
            signifiez votre acceptation de notre politique de confidentialité et
            acceptez que nous puissions collecter et utiliser vos données
            personnelles comme décrit dans cette déclaration de confidentialité.
          </p>
          <h4 data-aos="fade-right">Responsabilité</h4>
          <p data-aos="fade-up" className="para">
            Malgré l'attention portée à l'exactitude des informations fournies
            sur ce site, Nykse ne peut accepter aucune responsabilité quant à
            l'exactitude, la ponctualité et l'exhaustivité de ces informations.
            Nylse se réserve expressément le droit de modifier tout ou partie du
            contenu de ce site, de le supprimer ou de suspendre sa distribution,
            à tout moment et sans préavis.
          </p>
          <h4 data-aos="fade-right">Protection des Données</h4>
          <p data-aos="fade-up" className="para">
            Les données personnelles sont toutes les informations relatives à
            une personne identifiée ou identifiable (par exemple, un nom, mais
            aussi une adresse IP). La loi du 11 septembre 2007 sur la protection
            des données personnelles (LPrD: RSV 172.65) prévoit que chacun a
            droit à la protection de sa sphère privée ainsi qu'à la protection
            contre l'utilisation abusive des données le concernant. Les données
            personnelles ne sont ni vendues ni communiquées à des tiers. Tout
            sous-traitant reçoit exclusivement les données nécessaires à la
            tâche qui lui est confiée et est tenu par des obligations de
            confidentialité.
          </p>
          <p data-aos="fade-up" className="para">
            La communication entre votre navigateur et le serveur hébergeant
            notre site web est cryptée. Vos données personnelles sont transmises
            en toute sécurité à l'aide du cryptage SSL (Secure Socket Layer).
            Nous protégeons notre site web et nos autres systèmes en prenant des
            mesures raisonnables contre la perte, la destruction, l'accès, la
            modification et la diffusion de vos données par des personnes non
            autorisées. Malgré nos mesures, nous ne pouvons garantir que vos
            données ne seront pas altérées, détruites, sujettes à un accès non
            autorisé ou à un usage abusif. En visitant notre site et en
            utilisant nos services, vous comprenez et acceptez ces risques.
          </p>
          <h4 data-aos="fade-right">Utilisation des Données Personnelles</h4>
          <p data-aos="fade-up" className="para">
            Nous collectons vos données de deux manières. D'une part, lorsque
            vous les indiquez volontairement. Par exemple, en utilisant un
            formulaire de contact, ou lors du processus d'achat sur notre site.
            D'autre part, nous collectons les données suivantes : l'adresse IP,
            la date, l'heure, le numéro du logiciel de navigation ainsi que les
            informations généralement transmises au système d'exploitation,
            respectivement au logiciel de navigation. Nous pouvons être amenés à
            transmettre ces données à des tiers en cas d'obligation légale,
            notamment en vertu de la Loi fédérale sur la surveillance de la
            correspondance par poste et télécommunication (LSCPT) et de ses
            ordonnances d'exécution.
          </p>
          <p data-aos="fade-up" className="para">
            Nous utilisons vos données uniquement pour l'exécution de vos
            demandes, pour notre service client, l'administration technique et
            pour des évaluations statistiques anonymisées, servant à améliorer
            nos offres et la qualité de notre site.
          </p>
          <h4 data-aos="fade-right">Utilisation des Cookies</h4>
          <p data-aos="fade-up" className="para">
            Les cookies ou autres témoins sont utilisés et peuvent enregistrer
            automatiquement des informations personnelles (par exemple l'adresse
            IP, le navigateur ou l'appareil utilisé). Ces cookies permettent de
            rendre l'utilisation du site plus agréable et de collecter des
            informations à des fins d'analyse <br />
            (voir le chapitre Google Analytics).
          </p>
          <p data-aos="fade-up" className="para">
            Nous signalons que les données relatives à la recherche et à la
            navigation peuvent être traitées à des fins de ciblage publicitaire.
            Ces données sont ensuite utilisées notamment à des fins liées à la
            publicité, aux mesures statistiques, à la publicité géolocalisée ou
            encore à l'analyse du comportement des internautes dans le but de
            personnaliser la publicité qui leur est adressée.
            <br />
            Tous les navigateurs récents permettent aux internautes de s'opposer
            à l'enregistrement des cookies mais aussi de réaliser des sessions
            de navigation à l'issue desquelles les cookies installés lors de
            cette session sont effacés <br />
            (par exemple, le mode navigation privée sur Chrome).
          </p>
          <h4 data-aos="fade-right">Google Analytics</h4>
          <p data-aos="fade-up" className="para">
            Le site utilise Google Analytics, un service d'analyse de site
            internet fourni par Google Inc. ("Google"). Google Analytics utilise
            des cookies, qui sont des fichiers texte placés sur votre
            ordinateur, pour nous aider à analyser l'utilisation du site par ses
            utilisateurs. Les données générées par les cookies concernant votre
            utilisation du site (y compris votre adresse IP) seront transmises
            et stockées par Google sur des serveurs qui peuvent être situés à
            l'étranger. <br /> Google utilisera cette information dans le but
            d'évaluer l'utilisation du site par les internautes, de compiler des
            rapports sur l'activité du site et de fournir d'autres services
            relatifs à l'activité du site et à l'utilisation d'Internet. Google
            peut communiquer ces données à des tiers en cas d'obligation légale
            ou lorsque ces tiers traitent ces données pour le compte de Google.
          </p>
          <p data-aos="fade-up" className="para">
            Google ne recoupera pas votre adresse IP avec toute autre donnée
            détenue par Google. Vous pouvez également désactiver complètement
            l'utilisation de Google Analytics en utilisant le module
            complémentaire suivant : <br />
            <Link to={"https://tools.google.com/dlpage/gaoptout"}>
              http://tools.google.com/dlpage/gaoptout.
            </Link>{" "}
            <br />
            En utilisant le site, vous consentez expressément au traitement de
            vos données par Google et acceptez les conditions d'utilisation de
            Google.
          </p>
          <p data-aos="fade-up" className="para">
            Google a également adhéré au cadre juridique "Privacy Shield"
            couvrant les relations Suisse-États-Unis en matière de protection
            des données. Enfin, Google anonymise votre adresse IP avant de la
            sauvegarder.
          </p>
          <h4 data-aos="fade-right">Droit d'Accès et de Rectification</h4>
          <p data-aos="fade-up" className="para">
            Conformément à la Loi fédérale sur la protection des données, vous
            avez un droit d'information, d'opposition, d'accès, de rectification
            et de suppression des données vous concernant.
          </p>
          <p data-aos="fade-up" className="para">
            Ce droit peut être exercé à l'adresse suivante : sudio@nykse.ch
          </p>
        </div>
      </div>
    </section>
  );
};

export default DeclarationMain;
