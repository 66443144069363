import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  AccordionHome,
  ContactUs,
  Hero,
  Partners,
  Services,
  Testimonial,
  WeWorks,
} from "../components/homes";
import "../styles/home.scss";
import Footer from "../components/Footer";

const Home = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
      once: true, // whether animation should happen only once - while scrolling down
    });
    AOS.refresh(); // refresh animations when the component is loaded
  }, []);

  return (
    <div className="overflow-hidden">
      <Navbar page={"home"} />
      <Hero />
      <Partners />
      <Services />
      <WeWorks />
      <ContactUs />
      <Testimonial />
      <AccordionHome />
      <Footer />
    </div>
  );
};

export default Home;
