import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { IoIosArrowDown } from "react-icons/io";
import { accordionItems } from "../../data";

export default function AccordionHome() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="accordion-home">
      <div className="container">
        <div className="accordion-inner" data-aos="fade-up" data-aos-delay="200">
          {accordionItems.map((item) => {
            return (
              <Accordion
                key={item.id}
                expanded={expanded === item.id}
                onChange={handleChange(item.id)}
                className="accordion-item"
              >
                <AccordionSummary
                  expandIcon={
                    <IoIosArrowDown className="text-[22px] text-[#000000]" />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="accordion-title">
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="accordion-text">
                    {item.text}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
}
