import React from "react";

const TermsConditionMain = () => {
  return (
    <section className="termscondition-main">
      <div className="container">
        <h2 data-aos="fade-up" className="nunito">
          Conditions Générales
        </h2>
        <div className="termscondition-inner">
          <h4 data-aos="fade-right">1. Conditions contractuelles</h4>
          <p data-aos="fade-up" className="para">
            Les conditions générales suivantes définissent le cadre des
            prestations fournies par l’agence Nykse (désignée ci-après par
            Nykse) à ses clients. Elles règlent également la relation entre le
            client et Nykse.
          </p>
          <p data-aos="fade-up" className="para">
            Elles font partie intégrantes lors de commande de prestations du
            client, qu'elle soit écrite ou orale établie avec ou sans devis.
          </p>
          <h4 data-aos="fade-right">2. Forme écrite</h4>
          <p data-aos="fade-up" className="para">
            Toute dérogation aux conditions suivantes - ainsi qu'à la commande
            et notamment à la résiliation de celle-ci - doit être faite par
            écrit (courrier électronique étant suffisant).
          </p>
          <h4 data-aos="fade-right">3. Offre</h4>
          <p data-aos="fade-up" className="para">
            Toute demande d’offre soumise par un client doit être faite et
            confirmée par écrit (courrier électronique étant suffisant). Ladite
            offre se base sur les données récoltées par Nykse auprès du client,
            que cela soit par oral ou par écrit. Les données transmises par le
            client à Nykse doivent être claires, présentant précisément ses
            envies et/ou attentes et sans aucune équivoque quant au contenu, au
            but et à la forme du produit final.
          </p>
          <p data-aos="fade-up" className="para">
            Le prix de l’offre est calculé en fonction des indications données
            par le client, sur la base d’une estimation du travail nécessaire à
            la bonne réalisation du/des éléments/supports commandés. Tout
            travail supplémentaire ou changement requis par le client, non prévu
            et non indiqué à l’établissement de la première offre, entraînera
            des frais supplémentaires facturés au tarif horaire en vigueur (65
            CHF).
          </p>
          <p data-aos="fade-up" className="para">
            Les offres transmises et calculées sur la base d’indications
            imprécises ou fausses ne peuvent, en aucun cas, engager la
            responsabilité de Nykse. Les offres ont une validité de 30 jours.
            Suite à ce délai, Nykse se réserve le droit de modifier le prix
            et/ou les conditions de l’offre.
          </p>
          <h4 data-aos="fade-right">4. Confidentialité</h4>
          <p data-aos="fade-up" className="para">
            Nykse s’interdit de divulguer les informations et données qui lui
            sont communiquées par le client et stipulées expressément
            confidentielles. De son côté, le client s’interdit de divulguer
            toute donnée, formule ou programme, méthode ou renseignement dont il
            a connaissance du fait de l’exécution du présent contrat.
          </p>
          <h4 data-aos="fade-right">5. Garantie et responsabilités</h4>
          <p data-aos="fade-up" className="para">
            Obligations et responsabilité de Nykse : Nykse s’engage à tout
            mettre en œuvre pour que les solutions/produits pour la réalisation
            desquelles elle est engagée soient livrés dans les délais souhaités
            et le respect de la conformité de l’offre. <br /> La responsabilité
            de Nykse ne pourra être engagée en cas de :
          </p>
          <ul>
            <li data-aos="fade-left">
              Faute, négligence, manquement ou défaillance du client,
              non-respect des conseils donnés ;
            </li>
            <li data-aos="fade-left">
              Faute, négligence ou manquement d’un tiers non imposé par Nykse.
              Nykse n’est responsable que pour les prestations de tiers qu’elle
              s’est substituée. En cas de substitution, sa responsabilité est
              celle de l’art. 399 al. 2 CO (elle ne répond que du soin avec
              lequel elle a choisi le sous-mandataire et donné ses instructions)
              ;
            </li>
            <li data-aos="fade-left">
              Force majeure, événement ou incident indépendant de la volonté de
              Nykse ;
            </li>
            <li data-aos="fade-left">
              Destruction partielle ou totale des informations transmises ou
              stockées à la suite d’erreurs imputables au client.
            </li>
          </ul>
          <p data-aos="fade-up" className="para">
            La responsabilité de Nykse pour des dommages indirects est exclue.
            En cas de responsabilité de Nykse, celle-ci est dans tous les cas
            limitée à la somme estimée de la prestation concernée.
            <br />
            Obligations et responsabilité du client : Le client s’engage à :
          </p>
          <ul>
            <li data-aos="fade-left">
              Transmettre dès le début tous les éléments et informations
              nécessaires au bon déroulement du projet. Les travaux
              supplémentaires découlant de données incomplètes ou non-conformes
              seront facturés en sus au tarif horaire en vigueur et peuvent
              entraîner un retard dans l’exécution du mandat.
            </li>
            <li data-aos="fade-left">
              Utiliser de façon adéquate les services/produits et notamment les
              ressources qui lui sont allouées.
            </li>
            <li data-aos="fade-left">
              Respecter le droit suisse en matière de propriété intellectuelle
              (notamment les droits d’auteur).
            </li>
            <li data-aos="fade-left">
              Utiliser les réalisations de Nykse uniquement dans le cadre du
              mandat et dans le respect du droit suisse.
            </li>
          </ul>
          <p data-aos="fade-up" className="para">
            Validation client : Afin de garantir le bon déroulement d’un projet,
            le client est amené à valider les étapes clés de celui-ci. Cette
            validation engage le client quant à la conformité du travail livré.{" "}
            <br /> Ces validations (p. ex. présentation, BàT ou maquette de site
            web) sont à transmettre par écrit de même que toute demande de
            modification.
          </p>
          <p data-aos="fade-up" className="para">
            Chaque validation engage la responsabilité du client. Nykse ne peut
            être tenue responsable de la non-conformité des travaux validés. Des
            modifications demandées sur des éléments validés peuvent entraîner
            des coûts supplémentaires et une prolongation du délai.
          </p>
          <p data-aos="fade-up" className="para">
            Les étapes de validation sont définies d’avance et la planification
            du projet en dépend. Le délai du projet peut être prolongé en
            fonction des étapes de validation et délai de réponse du client.{" "}
            <br /> Le BAT ou maquette fourni par Nykse peut différer légèrement
            du produit final en termes de rendu.
          </p>
          <h4 data-aos="fade-right">6. Sous-traitance</h4>
          <p data-aos="fade-up" className="para">
            Nykse se réserve le droit de faire appel à des tiers pour
            l’exécution d’une partie du mandat (Imprimeur, photographe,
            traducteur, ...). Ces derniers peuvent faire l’objet d’une
            facturation séparée. Nykse s’engage à avertir le client et indiquer
            les prix pratiqués par ces derniers.
          </p>
          <h4 data-aos="fade-right">7. Conservation des données</h4>
          <p data-aos="fade-up" className="para">
            Sauf demande explicite, Nykse conserve et archive l’ensemble des
            travaux effectués par ses soins. Le client a le droit d’accéder à ce
            matériel tout au long du délai légal de conservation de 10 ans.{" "}
            <br /> Le client reste cependant le seul responsable de l’archivage
            et de la conservation de ses données, sauf contrat explicite.
          </p>
          <p data-aos="fade-up" className="para">
            Mise à part la partie publique du produit fini, seuls les employés
            de Nykse ont accès aux données transmises par le client. Nykse
            s’engage à ne partager ces informations qu’avec les partenaires
            connus du client.
          </p>
          <h4 data-aos="fade-right">8. Propriété intellectuelle</h4>
          <p data-aos="fade-up" className="para">
            Droits de Propriété Intellectuelle : Tous les contenus présents sur
            le site web de Nykse, y compris, mais sans s'y limiter, les textes,
            graphiques, logos, images, clips audio, vidéos, données,
            compilations de données et logiciels, sont la propriété de Nykse ou
            de ses concédants de licence, et sont protégés par les lois sur le
            droit d'auteur, les marques de commerce et autres droits de
            propriété intellectuelle.
          </p>
          <p data-aos="fade-up" className="para">
            Marques de Commerce : Les marques, logos et marques de service
            affichés sur le site (ci-après collectivement les "Marques") sont la
            propriété de Nykse ou de tiers. Les utilisateurs ne sont pas
            autorisés à utiliser ces Marques sans l'autorisation écrite
            préalable de Nykse ou du tiers propriétaire de la Marque. <br />{" "}
            Utilisation des Contenus : Nykse accorde aux utilisateurs une
            licence limitée, non exclusive, non transférable et révocable pour
            accéder et utiliser le site web de Nykse à des fins personnelles et
            non commerciales. Cette licence n'inclut aucun droit de :
          </p>
          <ul>
            <li data-aos="fade-left">Revendre ou utiliser commercialement les contenus du site ;</li>
            <li data-aos="fade-left">
              Collecter et utiliser les listes de produits, descriptions ou prix
              ;
            </li>
            <li data-aos="fade-left">Utiliser le site pour toute utilisation dérivée ;</li>
            <li data-aos="fade-left">
              Télécharger ou copier des informations pour le bénéfice d'un tiers
              ;
            </li>
            <li data-aos="fade-left">
              Utiliser des outils de collecte et d'extraction de données tels
              que des robots, spiders, ou similaires.
            </li>
          </ul>
          <p data-aos="fade-up" className="para">
            Toute utilisation non autorisée des contenus de ce site met fin à la
            licence accordée par Nykse. Les utilisateurs doivent respecter
            toutes les mentions de droit d'auteur, les informations et les
            restrictions contenues dans les contenus accessibles sur le site.{" "}
            <br /> Contenu Utilisateur : En publiant ou en soumettant du contenu
            sur le site de Nykse, les utilisateurs accordent à Nykse une licence
            mondiale, non exclusive, transférable, sous-licenciable, gratuite et
            perpétuelle pour utiliser, reproduire, distribuer, préparer des
            œuvres dérivées, afficher publiquement et exécuter publiquement ce
            contenu.
          </p>
          <p data-aos="fade-up" className="para">
            Les utilisateurs déclarent et garantissent qu'ils possèdent ou
            contrôlent tous les droits sur le contenu qu'ils publient, et que
            l'utilisation de leur contenu n'enfreint pas les droits de propriété
            intellectuelle de tiers, n'est pas illicite, et n'est pas
            susceptible d'être diffamatoire, obscène ou autrement répréhensible.{" "}
            <br />
            Propriété des Travaux Réalisés : Les créations réalisées par Nykse
            dans le cadre d'un contrat de prestation de services restent la
            propriété intellectuelle de Nykse jusqu'au paiement intégral des
            services rendus. À la réception du paiement complet, les droits de
            propriété intellectuelle peuvent être transférés au client selon les
            termes du contrat spécifique. <br />
            Sauf stipulation contraire dans le contrat, Nykse accorde au client
            une licence non exclusive et non transférable pour utiliser les
            créations fournies uniquement pour les finalités convenues. Nykse se
            réserve le droit d'utiliser ces créations à des fins de promotion et
            de portfolio, sauf accord contraire explicite avec le client.
          </p>
          <p data-aos="fade-up" className="para">
            Violations de la Propriété Intellectuelle : Si vous pensez que votre
            travail a été copié d'une manière qui constitue une violation du
            droit d'auteur ou que vos droits de propriété intellectuelle ont été
            autrement violés, veuillez nous fournir les informations suivantes
          </p>
          <ul>
            <li data-aos="fade-left">
              Une signature électronique ou physique de la personne autorisée à
              agir au nom du titulaire du droit d'auteur ou de tout autre droit
              de propriété intellectuelle ;
            </li>
            <li data-aos="fade-left">
              Une description de l'œuvre protégée par le droit d'auteur ou de
              tout autre droit de propriété intellectuelle que vous prétendez
              avoir été violé ;
            </li>
            <li data-aos="fade-left">
              Une description de l'endroit où se trouve le contenu que vous
              prétendez violer sur notre site ;
            </li>
            <li data-aos="fade-left">Votre adresse, numéro de téléphone et adresse e-mail ;</li>
            <li data-aos="fade-left">
              Une déclaration de votre part selon laquelle vous pensez de bonne
              foi que l'utilisation contestée n'est pas autorisée par le
              titulaire du droit d'auteur ou de tout autre droit de propriété
              intellectuelle, par son agent ou par la loi ;
            </li>
            <li data-aos="fade-left">
              Une déclaration de votre part, sous peine de parjure, que les
              informations ci-dessus dans votre notification sont exactes et que
              vous êtes le titulaire du droit d'auteur ou de tout autre droit de
              propriété intellectuelle ou autorisé à agir au nom du titulaire du
              droit d'auteur ou de tout autre droit de propriété intellectuelle.
            </li>
          </ul>
          <h4 data-aos="fade-right">9. Références au client</h4>
          <p data-aos="fade-up" className="para">
            Le client accepte que Nykse puisse faire figurer parmi ses
            références les travaux accomplis pour le client. Dans le cas où le
            client ne souhaite pas apparaître dans les références, il en formule
            expressément la demande par écrit.
          </p>
          <h4 data-aos="fade-right">10. Conservation des données</h4>
          <p data-aos="fade-up" className="para">
            Sauf demande explicite, Nykse conserve et archive l’ensemble des
            travaux effectués par ses soins. Le client a le droit d’accéder à ce
            matériel tout au long du délai légal de conservation de 10 ans.{" "}
            <br />
            Le client reste cependant le seul responsable de l’archivage et de
            la conservation de ses données, sauf contrat explicite.
          </p>
          <h4 data-aos="fade-right">
            11. Acceptation des conditions générales
          </h4>
          <p data-aos="fade-up" className="para">
            Par la validation de l’offre, le client accepte les présentes
            conditions générales. Le client reconnaît avoir été informé de
            l’existence de ces dernières et d’en avoir pris connaissance.
          </p>
          <h4 data-aos="fade-right">
            12. Droit applicable, for et disposition finale
          </h4>
          <p data-aos="fade-up" className="para">
            Le contrat principal conclu entre les parties et dont ces conditions
            générales font partie intégrante est soumis au droit suisse. Le for
            juridique est à Sainte-Croix, Suisse.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsConditionMain;
