import React, { useState } from "react";
import sms from "../../assets/home/section-4/sms.svg";
import { projectIframes } from "../../data";

const WeWorks = () => {
  const [projects, setProjects] = useState(projectIframes.slice(0, 4));
  const [empty, setEmpty] = useState(false);

  const handleSeeMore = () => {
    let projectLength = projects.length;
    let seeMoreProjects = projectIframes.slice(0, projectLength + 2);
    setProjects(seeMoreProjects);

    if (projects.length == projectIframes.length) {
      setEmpty(true);
    }
  };

  return (
    <section className="we-works">
      <div className="container">
        <div className="we-works-header sm:flex gap-8">
          <div data-aos-delay="300" data-aos="fade-right">
            <img src={sms} alt="" />
          </div>
          <div className="max-sm:pt-3" data-aos="fade-right">
            <p className="noto">
              <span className="text-[#FEC90C]">//</span> 03. projet
            </p>
            <h2>
            Ici, tu retrouveras nos projets récents.
            </h2>
          </div>
        </div>
        <div className="we-works-inner">
          <div className="box-all grid grid-cols-1 md:grid-cols-2 gap-8">
            {projects.map((item) => {
              return (
                <div
                  key={item.id}
                  className="gray-box"
                  data-aos="fade-up"
                  data-aos-delay={item.id + "00"}
                >
                  <iframe
                    src={item.src}
                    height="316"
                    width="404"
                    allow="clipboard-write"
                  ></iframe>
                </div>
              );
            })}
          </div>

          {empty && <p className="text-center mt-5">Rightnow No More Project</p>}
          <button onClick={handleSeeMore} className="see-more-btn noto">
            SEE MORE
          </button>
        </div>
      </div>
    </section>
  );
};
export default WeWorks;
