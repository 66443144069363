import React from "react";
import slack from "../../assets/home/section-2/slack.svg";
import prudential from "../../assets/home/section-2/prudential.svg";
import zoover from "../../assets/home/section-2/zoover.svg";
import zoover1 from "../../assets/home/section-2/zoover1.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="partners">
      <div className="container">
        <div className="partners-inner">
          <Slider {...settings}>
            <div data-aos="fade-right">
              <img src={slack} alt="slack" />
            </div>
            <div data-aos="fade-right" data-aos-delay="400">
              <img src={prudential} alt="slack" />
            </div>
            <div data-aos="fade-left" data-aos-delay="400">
              <img src={zoover} alt="slack" />
            </div>
            <div data-aos="fade-left">
              <img src={zoover1} alt="slack" />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Partners;
