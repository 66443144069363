import React, { useEffect, useState } from "react";
import video from '../assets/video.mp4'
import mobileVideo from '../assets/mobile-video.mov'

const VideoOverlay = () => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("videoPlayed")) {
      setShowVideo(true);
      localStorage.setItem("videoPlayed", "true");
    }
  }, []);

  const handleVideoEnd = () => {
    setShowVideo(false);
  };

  return (
    showVideo && (
      <div className="hidden sm:block" style={styles.videoOverlay}>

        <video className="hidden sm:block" style={styles.introVideo} autoPlay muted onEnded={handleVideoEnd}>
          <source src={video} type="video/mp4" />
            Votre navigateur ne supporte pas la vidéo.
        </video>
        {/* <video className="block sm:hidden" style={styles.introVideo} autoPlay muted onEnded={handleVideoEnd}>
          <source src={mobileVideo} type="video/mp4" />
            Votre navigateur ne supporte pas la vidéo.
        </video> */}
      </div>
    )
  );
};

const styles = {
  videoOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "black",
    zIndex: 1111111,
  },
  introVideo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export default VideoOverlay;
