// Services Card all
import parlons from "./assets/home/section-3/parlons.svg";
import cards from "./assets/home/section-3/cards.svg";
import favorite from "./assets/home/section-3/favorite-chart.svg";

// Carousel Items
import company from "./assets/home/section-6/Company.svg";
import company1 from "./assets/home/section-6/Company (1).svg";
import man from "./assets/home/section-6/man.svg";
import { Man } from "@mui/icons-material";

export const servicesCardAll = [
  {
    id: 200,
    image: parlons,
    title: "Parlons",
    text: "Contacte-nous pour parler de ton projet.",
  },
  {
    id: 400,
    image: cards,
    title: "Œuvrons",
    text: "Nous œuvrerons pour créer le meilleur résultat possible pour toi.",
  },
  {
    id: 600,
    image: favorite,
    title: "Célébrons",
    text: "Lève-toi avec le sourire aux lèvres.",
  },
];

export const carouselItems = [
  {
    id: 1,

    details:
      "Studio exceptionnel, offrant une qualité et une expertise parfaitement alignées avec les attentes des clients. Merci pour votre excellent travail !",
    name: "DJ NOV",
    pod: "Disc Jockey",
  },
  {
    id: 3,

    details:
      "Nous avons eu le plaisir de collaborer avec un designer sérieux et à l'écoute, qui a parfaitement compris et interprété nos idées. Son professionnalisme et sa compétence font de lui un partenaire de choix pour tous nos projets créatifs. Une note de 5 étoiles s'impose évidemment.",
    name: "Epok",
    pod: "streetwear brand",
  },
  {
    id: 2,
 
    details:
      "Super service je tiens à féliciter. Le travail a été réalisé dans les temps, une bonne réactivité, à l'écoute, rapide… Je recommande !!",
    name: "Anthony",
    pod: "Independant",
  },
  {
    id: 4,
 
    details:
      "Je suis très satisfait de vos services sur mesure. Vous êtes très réactifs, patient, à l'écoute. Je recommande à mon entourage et vous remercie pour la très bonne qualité de vos services. ",
    name: "Nathan",
    pod: "L'Association Passion Secours",
  },
];

export const accordionItems = [
  {
    id: 1,
    title: "Quels types de services de design offrez-vous ?",
    text: "Nous proposons une gamme de services, y compris le design graphique, le design web, le design d'identité de marque, l'illustration, et le design d'emballage. Nous travaillons avec des entreprises de toutes tailles pour créer des solutions visuelles impactantes. ",
  },
  {
    id: 2,
    title: "Comment fonctionne votre processus de design ?",
    text: "Notre processus commence par une consultation initiale pour comprendre vos besoins et objectifs. Ensuite, nous procédons à une phase de recherche et de brainstorming, suivie de la création de concepts. Après votre approbation, nous affinons le design jusqu'à ce que vous soyez entièrement satisfait.",
  },
  {
    id: 3,
    title: "Combien de temps prend un projet typique ?",
    text: "La durée d'un projet dépend de sa complexité et de vos exigences spécifiques. Un projet de design graphique simple peut prendre quelques jours, tandis qu'un site web complet peut prendre plusieurs semaines. Nous vous fournirons un calendrier détaillé au début du projet.",
  },
  {
    id: 4,
    title: "Comment déterminez-vous le coût d'un projet ?",
    text: "Le coût est déterminé en fonction de la complexité du projet, du temps estimé et des ressources nécessaires. Notre tarif est de 45 € de l'heure. Nous offrons des devis personnalisés après avoir discuté de vos besoins spécifiques. Contactez-nous pour une consultation gratuite et un devis personnalisé.",
  },
  {
    id: 5,
    title: "Travaillez-vous avec des clients internationaux ?",
    text: "Oui, nous avons l'expérience de travailler avec des clients du monde entier. Grâce à la technologie, nous pouvons collaborer efficacement quel que soit votre emplacement.",
  },
];

export const projectIframes = [
  {
    id: 2,
    src: "https://www.behance.net/embed/project/199683469?ilo0=1",
  },
  {
    id: 4,
    src: "https://www.behance.net/embed/project/198294901?ilo0=1",
  },
  {
    id: 6,
    src: "https://www.behance.net/embed/project/195586743?ilo0=1",
  },
  {
    id: 8,
    src: "https://www.behance.net/embed/project/197176997?ilo0=1",
  },
  {
    id: 10,
    src: "https://www.behance.net/embed/project/190318535?ilo0=1",
  },
  {
    id: 12,
    src: "https://www.behance.net/embed/project/192018649?ilo0=1",
  },
  
  {
    id: 14,
    src: "https://www.behance.net/embed/project/189383025?ilo0=1",
  },
  {
    id: 16,
    src: "https://www.behance.net/embed/project/179272279?ilo0=1",
  },
]