import React, { useState } from "react";
import "../styles/navbar.scss";
import { Link } from "react-router-dom";
import { HashLink as LinkHash } from 'react-router-hash-link';
import logo from "../assets/logo.svg";
import { CgMenu } from "react-icons/cg";

const Navbar = ({ page }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <header className={`${mobileMenu ? "header-menu" : ""}`}>
      <div className="container">
        <nav className={`navbar`}>
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" />
          </Link>
          <div className={`navbar-collapse md:flex hidden`}>
            <ul>
              {page === "home" ? null : (
                <li>
                  <Link to="/">Home</Link>
                </li>
              )}
              <li>
                <LinkHash to="/#services">Services</LinkHash>
              </li>
              <li>
                <LinkHash to="/#contact">Contact</LinkHash>
              </li>
              <li>
                <LinkHash to="/#avis">Avis</LinkHash>
              </li>
              {page === "qui" ? null : (
                <li>
                  <Link to="/qui">Qui sommes-nous ?</Link>
                </li>
              )}
              <li>
              <LinkHash to="/#services" className="nos-btn">
              Nos projets
            </LinkHash>
              </li>
            </ul>
          </div>
          <button
            onClick={handleMobileMenu}
            className={`hamburger-btn md:hidden`}
          >
            <CgMenu className="text-[30px]" />
          </button>
        </nav>
      </div>
      <div
        className={`mobile-menu-items ${mobileMenu ? "menuOpen" : "menuClose"}`}
      >
        <ul>
          {page === "home" ? null : (
            <li>
              <Link to="/" onClick={closeMobileMenu}>Home</Link>
            </li>
          )}
          <li>
            <LinkHash to="/#services" onClick={closeMobileMenu}>Services</LinkHash>
          </li>
          <li>
            <LinkHash to="/#contact" onClick={closeMobileMenu}>Contact</LinkHash>
          </li>
          <li>
            <LinkHash to="/#avis" onClick={closeMobileMenu}>Avis</LinkHash>
          </li>
          {page === "qui" ? null : (
            <li>
              <Link to="/qui" onClick={closeMobileMenu}>Qui sommes-nous ?</Link>
            </li>
          )}
          <li>
            <LinkHash to="/#services" className="nos-btn" onClick={closeMobileMenu}>
              Nos projets
            </LinkHash>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
