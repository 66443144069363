import React from "react";
import sms from "../../assets/home/section-4/sms.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoStar } from "react-icons/io5";
import { carouselItems } from "../../data";

const Testimonial = () => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="testimonial" id="avis">
      <div className="container">
        <div className="we-works-header sm:flex gap-8">
          <div data-aos-delay="300" data-aos="fade-right">
            <img src={sms} alt="ImageLikeSmS" />
          </div>
          <div className="max-sm:pt-3" data-aos="fade-right">
            <p className="noto">
              <span className="text-[#FEC90C]">//</span> 05 . Avis
            </p>
            <h2>
              Nous collaborons avec des <br /> clients de tous les secteurs.
            </h2>
          </div>
        </div>
        <div className="testimonial-inner">
          <div className="slider-container">
            <Slider {...settings}>
              {carouselItems.map((item) => {
                return (
                  <div key={item.id} className="slider-card">
                    <div className="card-header">
                      <div className="flex justify-between items-center hdr">
                        <img src={item.image} alt="" />
                        <div className="flex star">
                          <IoStar />
                          <IoStar />
                          <IoStar />
                          <IoStar />
                          <IoStar />
                        </div>
                      </div>
                      <hr />
                      <p className="para noto">{item.details}</p>
                    </div>
                    <div className="card-footer">
                      <div className="flex items-center gap-4">
                        <div>
                          <img src={item.manImage} alt="" />
                        </div>
                        <div>
                          <h4 className="nunito">{item.name}</h4>
                          <p className="noto">{item.pod}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
